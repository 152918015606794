import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Alert, AlertIcon, Box, Button, Input, InputGroup, InputLeftAddon, Stack, StatLabel } from '@chakra-ui/react';
import { AppTemplate } from '../../shared/ui/AppTemplate';
import { sendLeadToVakas } from '../../shared/api/auth';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../shared/enums/routes';
import { Typography } from 'antd';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

function isNumeric(value: string) {
  if (value === '') {
    return true;
  }

  return /^-?\d+$/.test(value);
}

const utm_source = 'alekskazanmt.missis-laser.ru';
const utm_term = 'ALEKSKAZAN';
const utm_campaign = 'ALEKSKAZAN';
const utm_medium = 'referral';
const budget = 1990;

export const Home = observer(() => {
  const [error, setError] = useState<boolean>();
  const [errorText, setErrorText] = useState<string | null>(null);
  //* ==================
  const [username, setUserName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  //* ==================
  const [city, setCity] = useState<string>('Казань');

  React.useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
      authDomain: 'missis-laser.firebaseapp.com',
      projectId: 'missis-laser',
      storageBucket: 'missis-laser.appspot.com',
      messagingSenderId: '514174044584',
      appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
      measurementId: 'G-QTNN569ZLV',
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, 'alekskazanmt');
  }, []);

  //* ==================
  const history = useHistory();

  const onSubmit = async () => {
    try {
      await sendLeadToVakas({
        budget,
        city,
        name: username,
        phone: '+7' + phone,
        utm_term,
        utm_source,
        utm_campaign,
        utm_medium,
      }).then(async () => {
        history.push(Routes.Success);
      });
    } catch (error) {
      console.log(error);
      history.push(Routes.Error);
    }
  };

  return (
    <AppTemplate
      headerTitle='Тест драйв'
      headerBody={
        <StatLabel style={{ textAlign: 'center', fontSize: 14 }}>
          🎁Всем новым клиентам дарим купон 6000 руб на услуги эпиляции александритовым лазером!
          <br />
          <StatLabel style={{ textAlign: 'center', fontSize: 14, marginTop: 6 }}>
            <b>Закрепить за Вами купон можно до 19 марта!</b>
          </StatLabel>
          <StatLabel style={{ textAlign: 'center', fontSize: 14, marginTop: 6 }}>
            Не упустите возможность и оставляйте заявку!
          </StatLabel>
          <br />
        </StatLabel>
      }
      //headerBody={`Акция для новых клиентов только в Январе!\nЛазерная эпиляция зон Бикини + Подмышки за 990 руб вместо 2300 руб! Пока есть возможность - оставляйте заявку и приходите на процедуру! Ждём Вас!`}
    >
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack spacing='2rem'>
          <Stack spacing='1rem'>
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Ваши контактные данные
            </Typography.Title>
            <InputGroup>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='Введите ваше имя'
                value={username}
                onChange={e => {
                  setUserName(e.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={10}
                type='tel'
                /* isInvalid={error} */
                placeholder='Введите ваш телефон'
                value={phone}
                onChange={event => {
                  isNumeric(event.target.value) && setPhone(event?.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            {/*  <InputGroup>
              <Select
                value={city}
                onChange={event => {
                  setCity(event?.target.value);
                  setError(false);
                }}
                placeholder='Выберите город'
              >
                <option value='Казань'>Казань</option>
              </Select>
            </InputGroup> */}
          </Stack>
          {error && (
            <Stack spacing='1rem' style={{ marginTop: 12 }}>
              {errorText && (
                <Alert status='error'>
                  <AlertIcon />
                  {errorText}
                </Alert>
              )}
            </Stack>
          )}
          <Button
            /* style={{ marginBottom: 16 }} */
            onClick={() => {
              if (city === '' || phone === '' || username === '') {
                setError(true);
                setErrorText(
                  city === ''
                    ? 'Вы не указали город'
                    : phone === ''
                    ? 'Вы не указали свой номер'
                    : username === ''
                    ? 'Вы не указали своё имя'
                    : null
                );
              } else {
                onSubmit();
              }
            }}
            mt='24px'
            w='100%'
            h='60px'
            alignSelf='center'
            colorScheme='teal'
            variant='solid'
          >
            Хочу купон
          </Button>
          <Typography.Paragraph
            style={{ textAlign: 'center', marginBottom: 16, marginTop: 8, textAlignLast: 'left', fontSize: 12 }}
          >
            * Акция действует только для клиники в г. Казань
          </Typography.Paragraph>
        </Stack>
      </Box>
    </AppTemplate>
  );
});
